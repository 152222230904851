import { default as _91slug_93RPYB4NvKKjMeta } from "/opt/build/repo/pages/[slug].vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as _91id_9370KLzPxTxrMeta } from "/opt/build/repo/pages/stops/[id].vue?macro=true";
export default [
  {
    name: _91slug_93RPYB4NvKKjMeta?.name ?? "slug",
    path: _91slug_93RPYB4NvKKjMeta?.path ?? "/:slug()",
    meta: _91slug_93RPYB4NvKKjMeta || {},
    alias: _91slug_93RPYB4NvKKjMeta?.alias || [],
    redirect: _91slug_93RPYB4NvKKjMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_9370KLzPxTxrMeta?.name ?? "stops-id",
    path: _91id_9370KLzPxTxrMeta?.path ?? "/stops/:id()",
    meta: _91id_9370KLzPxTxrMeta || {},
    alias: _91id_9370KLzPxTxrMeta?.alias || [],
    redirect: _91id_9370KLzPxTxrMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/stops/[id].vue").then(m => m.default || m)
  }
]