import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoIconArrowRight, LazySvgoIconMap, LazySvgoIconVehicle, LazySvgoLogoMobilis, LazySvgoLogoVmcv } from '#components'
const lazyGlobalComponents = [
  ["SvgoIconArrowRight", LazySvgoIconArrowRight],
["SvgoIconMap", LazySvgoIconMap],
["SvgoIconVehicle", LazySvgoIconVehicle],
["SvgoLogoMobilis", LazySvgoLogoMobilis],
["SvgoLogoVmcv", LazySvgoLogoVmcv]
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
